@import '_base.scss';

.header {
  &_wrap {
    position: relative;
  }

  &_image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.75;

    @media only screen and (max-width: 767px) {
      object-position: 45px 45px;
      transform: scale(2);
    }

    @media only screen and (min-width: 768px) {
      object-position: 80px -20px;
      transform: scale(2);
    }

    @media only screen and (min-width: 1024px) {
      object-position: 0 -24px;
      transform: scale(1);
    }

    &_container {
      position: relative;
      // height: 235px;
      height: 400px;
      overflow: hidden;

      @media only screen and (max-width: 767px) {
        height: 250px;
      }
    }
  }

  &_text {
    position: absolute;
    top: 10%;
    text-align: center;
    width: 100%;
  }

  &_title {
    text-align: center;
    color: white;
    text-shadow: 0 5px 0 black;
    font-family: $norwester;
    font-size: 60px;
    margin-bottom: 12px;

    @media only screen and (max-width: 767px) {
      font-size: 32px;
      margin-bottom: 0;
    }
  }

  &_address_wrap {
    font-family: $barlow-medium;
    font-size: 24px;
    color: white;
    text-shadow: 0 5px 10px black;

    @media only screen and (max-width: 767px) {
      font-size: 18px;
      margin-top: $medium/2;
    }
  }

  &_email {
    color: inherit;
  }
}

.body_text {
  max-width: 1024px;
  margin: 0 auto;
  background-color: white;
  font-family: $barlow-medium;
  font-size: 20px;
  box-shadow: 0 0 500px rgba(0, 0, 0, 0.5);
  padding: $medium 0;
  color: $footerblue;

  @media only screen and (max-width: 767px) {
    padding-bottom: 1px;
  }
}

.callToAction {
  text-align: center;
  margin-bottom: $large;
  padding-bottom: $large;
  color: $darkblue;

  @media only screen and (max-width: 767px) {
    margin-bottom: $medium;
  }

  & &_text {
    margin: 0;

    @media only screen and (max-width: 767px) {
      font-size: 1.2em;
    }

    &__spanish {
      margin: 0;

      @media only screen and (max-width: 767px) {
        font-size: 1.1em;
      }
    }
  }
}

.pricing {
  &_text_wrap {
    width: 60%;
    margin: auto;
    margin-bottom: $large;
    background-color: $darkblue;
    padding: $large;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    color: white;
    text-align: center;

    @media only screen and (max-width: 767px) {
      width: calc(100% - 80px);
      padding: $medium;
      font-size: 18px;
      margin-bottom: $medium;
    }
  }

  &_title {
    margin-bottom: $medium;
    font-size: 24px;
    font-weight: 600;

    @media only screen and (max-width: 767px) {
      margin-bottom: $medium/2;
      font-size: 20px;
    }
  }
}

.details {
  margin-bottom: $medium;
  font-size: 18px;

  &_wrap {
    width: 80%;
    margin: auto;
    margin-bottom: $large;

    @media only screen and (max-width: 767px) {
      font-size: 16px;
      margin-bottom: $medium;
    }
  }
}

.valediction {
  &_wrap {
    margin-left: 12%;
    margin-bottom: $large;
    color: $footerblue;
    font-size: 18px;

    @media only screen and (max-width: 767px) {
      font-size: 16px;
    }
  }

  &_signature {
    font-family: $alex;
    font-size: 40px;

    @media only screen and (max-width: 767px) {
      font-size: 32px;
    }
  }
}

.schedule {
  &_wrap {
    width: 60%;
    margin: auto;
    background-color: $darkblue;
    padding: $large;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    color: white;
    text-align: center;

    @media only screen and (max-width: 767px) {
      width: calc(100% - 80px);
      padding: $medium;
      font-size: 18px;
      margin-bottom: $medium;
    }
  }

  &_title,
  &_title_evenings,
  &_title_spanish {
    margin-bottom: $medium;
    font-size: 24px;
    font-weight: 600;

    @media only screen and (max-width: 767px) {
      font-size: 20px;
      margin-bottom: $medium/2;
    }
  }

  &_times {
    &_wrap,
    &_evenings_wrap {
      margin-bottom: $medium;

      @media only screen and (max-width: 767px) {
        font-size: 18px;
      }
    }
  }
}

.footer {
  text-align: center;
  padding: $large;
  background-color: $footerblue;
  color: rgba(255, 255, 255, 0.5);
  font-family: $barlow-light;
  font-size: 18px;

  @media only screen and (max-width: 767px) {
    padding: $medium;
    font-size: 16px;
  }

  &_section {
    margin-bottom: $medium/2;
  }

  &_email {
    color: inherit;
  }
}

.converge_form {
  display: flex;
  flex-direction: column;
  width: 40%;
  text-align: center;
  margin: 0 auto;
  background-color: $lightblue;
  padding: $medium;
  padding-bottom: 0;
  border-radius: 5px;
  margin-bottom: $large;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);

  @media only screen and (max-width: 767px) {
    width: auto;
    margin-bottom: $medium;
    border-radius: 0;
  }

  .converge_button {
    font-family: $barlow-medium;
    font-size: 20px;
    font-weight: 700;
    padding: 8px;
    margin-bottom: 10px;
    border: 0;
    border-radius: 5px;
    background-color: #ffc439;
    color: rgb(48, 48, 48);
    transition: all 0.15s;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);

    &:hover,
    &:focus {
      background-color: rgb(243, 181, 35);
      color: rgb(20, 20, 20);
      cursor: pointer;
    }
  }

  .converge_cc {
    width: 200px;
    margin: 0 auto;
    margin-bottom: 5px;
  }

  .converge_buttonText {
    margin-right: 10px;
  }

  .converge_pB {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
  }

  .converge_buttonText {
    font-size: 14px;
    line-height: 1;
    opacity: 0.5;
  }

  .converge_logo {
    width: 115px;
    padding-top: 3px;
  }
}

.hoo_wrap {
  text-align: center;
  margin-bottom: $large;
  padding-top: $medium;
  padding-bottom: $large;
  padding-left: $medium;
  padding-right: $medium;
  color: $darkblue;

  @media only screen and (max-width: 767px) {
    margin-bottom: 20px;
    margin-top: 20px;

    & > h2 {
      font-size: 24px;
    }

    & > div {
      font-size: 18px;
    }
  }
}

.director_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $medium;
  margin: $large $medium;
  padding: $large $medium;

  .director_img {
    width: 100px;
    height: 150px;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
    object-fit: cover;
  }
}
