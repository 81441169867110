@import '_base.scss';

.app {
  animation: fadein 2s;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.disableScroll {
  overflow: hidden;
}
