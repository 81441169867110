$darkblue: #145873;
$tan: #F2D8A7;
$brown: #BF7245;
$footerblue: #202936;
$lightblue: #DBE4E8;

/* Fonts */
@font-face {
  font-family: 'norwesterregular';
  src: url('fonts/norwester.woff2') format('woff2'),
       url('fonts/norwester.woff') format('woff'),
       url('fonts/norwester.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'barlowlight';
  src: url('fonts/barlow-light.woff2') format('woff2'),
       url('fonts/barlow-light.woff') format('woff'),
       url('fonts/barlow-light.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'barlowmedium';
  src: url('fonts/barlow-medium.woff2') format('woff2'),
       url('fonts/barlow-medium.woff') format('woff'),
       url('fonts/barlow-medium.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'alex_brushregular';
  src: url('fonts/alexbrush-regular.woff2') format('woff2'),
       url('fonts/alexbrush-regular.woff') format('woff'),
       url('fonts/alexbrush-regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

$norwester: norwesterregular, Arial, sans-serif !default;
$barlow-light: barlowlight, Arial, sans-serif !default;
$barlow-medium: barlowmedium, Arial, sans-serif !default;
$alex: alex_brushregular, Arial, cursive !default;


/* Spacing */
$medium: 20px;
$large: 40px;