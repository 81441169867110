@import '_base.scss';

.paymentSuccessWrap {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .darkenBG {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1;
  }
  .successModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 80%;
    max-width: 500px;
    background-color: white;
    padding: 20px;
    margin-top: 20%;
    border-radius: 5px;
    z-index: 1;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);

    &_button {
      margin-left: auto;
      background-color: transparent;
      border: none;
      font-size: 24px;
      cursor: pointer;
    }

    &_h1 {
      font-family: $barlow-medium;
      text-align: center;
      margin: 0;
    }

    &_h2 {
      font-family: $barlow-medium;
      margin-top: 10px;
    }
  }
}
